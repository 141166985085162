.disabled {
    pointer-events: none !important;
    opacity: 0.5;
    cursor: not-allowed;
}

.nav-tabs .nav-link {
    border: 2px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
    width: 50%;
}

input:focus-visible {
    outline-offset: none !important;
}

.invalid-faq-icon {
    outline: rgb(235, 117, 118) solid 2px !important;
    color: rgb(235, 117, 118) !important;
}

input[type="tel"]:valid {
    color: #0061A0;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.nav-link.active {
    border-color: #0061a0 #0061a0 #fff !important;
}

.nav-tab.nav-link {
    border-bottom-color: #0061a0 !important;
}

.error-message {
    /*display: none;*/
    color: #EB7576;
    font-size: 20px;
}

.error-message-2 {
    display: none;
    color: #EB7576;
    font-size: 1rem;
}

.form-control {
    font-size: 1.5em;
    color: #0061A0;
    font-weight: 700;
    text-align: center;
}

.form-control.invalid {
    border: 1px solid #EB7576 !important;
    color: #EB7576;
}

button:focus-visible {
    outline: none !important;
}

a:hover,
a:focus {
    text-decoration: none !important;
}

.form-control {
    font-size: 1.25rem;
}

.form-control:focus {
    color: #007ABC;
    background-color: #fff;
    border: 2px solid #007ABC;
    outline: 0;
    box-shadow: none !important;
    border-radius: 10px;
}

.form-control:active {
    color: #0061A0;
}

.form-control>#date-input {
    width: 50% !important;
}

.form-control::placeholder {
    text-align: center;
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.center-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
}

/* Date Selector */
.datepicker {
    display: block;
    padding: 10px !important;
    box-shadow: #989696b8;
    border-radius: 0px 0px 10px 10px;
    border-top: solid 7px;
    border-image-slice: 1;
    border-image-source: linear-gradient(180deg, #0090DA 0%, #A4CE4E 100%);
    outline: 3px solid #3498db;
    z-index: 10;
}

/* form -otp */
.section__show-otp>.card {
    width: 350px;
    padding: 20px;
    background: #fff;
    height: 350px;
    position: relative;
}

.section__show-otp>.mobile-text {
    color: #989696b8;
    font-size: 15px;
}

.section__show-otp>.form-control {
    margin-right: 12px;
}

.section__show-otp>.form-control:focus {
    color: #495057;
    outline: 0;
    box-shadow: none;
}

/* FAQ Popup Icon */
.faq-button {
    width: 30px;
    height: 30px;
    background-color: #fff;
    color: #3498db;
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
    font-style: normal;
    outline: 2px solid #3498db;
    position: absolute;
}

.faq-button:hover {
    background-color: #3498db;
    color: #fff !important;
    border: solid 2px;
}

.wrapper-popup {
    margin-top: 30px !important;
    margin-left: 35px !important;
    position: fixed;
    background-color: white;
    padding: 5px 0 5px 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border-left: solid 7px;
    border-image-slice: 1;
    border-image-source: linear-gradient(180deg, #0090DA 0%, #A4CE4E 100%);
    /* width: 352px; */
    width: 380px;
    outline: 2px solid #3498db;
    z-index: 1200;
}

.wrapper-popup li {
    padding: 0 !important;
    ;
    margin: 0;
    font-size: 14px;
}

.popup {
    border-left: none;
}

/* #ngaySinh{
  width: 100%!important;
} */
#close-button {
    margin-top: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

#popup0,
#popup1,
#popup6,
#popup7 {
    display: none;
}

#popup0>.wrapper-popup {
    bottom: 16vh;
}

/* // Responsive  */
@media only screen and (max-width: 1200px) and (min-width: 520px) {
    .section__input-date>.form-control {
        width: 70%;
    }
}

@media only screen and (max-width: 930px) and (max-height: 420px) {
    .footer__content--mb {
        z-index: 0;
    }

    .wrapper-popup {
        left: 22vw;
        bottom: 20vh;
        transition: auto;
    }

    .header__title>h4 {
        color: #FFF;
        text-align: center;
        font-family: NotoSans-Bold, sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: auto;
        padding: -2px;
    }

    .pt-4 {
        padding-top: 0 !important;
    }

    .pb-1 {
        padding-bottom: 0 !important;
    }

    .header__logo>img {
        padding: 0 0;
    }

    .py-2 {
        padding: 0 !important;
    }

    .section_footer {
        background: #fff0;
    }

    .unit {
        font-size: 24px;
    }

    #popup5>.wrapper-popup {
        top: 51vh !important;
    }

    .continue-progress {
        /* position: fixed; */
        /* left: 60px; */
        z-index: 1300;
        right: 2vw;
        bottom: 30px;
    }

    .logout-process {
        /* position: fixed; */
        /* left: 60px; */
        z-index: 1300;
        right: 35vw;
        bottom: 30px;
    }

    .padding-page-2 {
        padding-top: 9rem !important;
    }

    .footer__content {
        color: #000;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        background: white;
    }

    .content {
        padding-top: 120px;
        padding-bottom: 100px;
    }

    .section__input-date>.form-control {
        border-radius: 10px;
        border: 1px solid #A7A8AA;
        background: #FFF;
        display: flex;
        width: 60%;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        color: #A7A8AA;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

@media only screen and (min-width: 768px) and (max-width: 811px) {
    .wrapper-popup {
        position: absolute;
        /*left: 38vw;*/
        right: 100%;
        /*bottom: 71vh;*/
        transition: auto;
    }
    #popup5>.wrapper-popup {
        /*top: 63vh;*/
        right: 100%;
        bottom: auto;
    }

    #popup4>.wrapper-popup {
        /*top: 53vh;*/
        right: 100%;
        bottom: auto;
    }

    #popup3>.wrapper-popup {
        /*top: 43vh;*/
        right: 100%;
        bottom: auto;   
    }

    #popup0>.wrapper-popup {
        /*left: 56vw;*/
        right: 100%;
    }

    #popup6>.wrapper-popup {
        right: auto;
    }
}
@media only screen and (min-width: 540px) and (max-width: 767px) {
    .wrapper-popup {
        left: 38vw;
        bottom: 70vh;
        transition: auto;
    }
    #popup5>.wrapper-popup {
        top: 60vh;
    }

    #popup4>.wrapper-popup {
        top: 50vh;
    }

    #popup3>.wrapper-popup {
        top: 40vh;
    }

    #popup0>.wrapper-popup {
        left: 56vw;
    }
}

@media only screen and (max-width: 376px) {

    .pl-5,
    .px-5 {
        padding-left: 41px !important;
    }

    .faq-button-6 {
        right: 19px !important;
    }
}
@media only screen and (min-width: 1537px) and (max-width: 1610px) {
    .wrapper-popup {
        left: 51vw;
        transition: auto;
    }

    #popup5>.wrapper-popup {
        top: 60vh;
    }

    #popup4>.wrapper-popup {
        top: 50vh;
    }

    #popup3>.wrapper-popup {
        top: 40vh;
    }

    #popup0>.wrapper-popup {
        left: 56vw;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1536px) {
    .wrapper-popup {
        position: absolute;
        /*left: 51vw;*/
        right: 100%;
        transition: auto;
    }

    #popup5>.wrapper-popup {
        /*top: 28vh;*/
        right: 100%;
    }

    #popup4>.wrapper-popup {
        right: 100%;
        /*top: 24vh;*/
    }

    #popup3>.wrapper-popup {
        right: 100%;
        /*top: 20vh;*/
    }

    #popup0>.wrapper-popup {
        right: 100%;
        /*left: 56vw;*/
    }

    #popup6>.wrapper-popup {
        right: auto;
    }
}


@media only screen and (min-width: 811px) and (max-width: 1023px) {
    .wrapper-popup {
        position: absolute;
        /*left: 40vw;*/
        transition: auto;
        right: 100%;
        /*bottom: 74vh;*/
    }

    #popup5>.wrapper-popup {
        /*top: 60vh;*/
        bottom: auto;
        right: 100%;
    }

    #popup4>.wrapper-popup {
        /*top: 50vh;*/
        right: 100%;
        bottom: auto;
    }

    #popup3>.wrapper-popup {
        /*top: 40vh;*/
        right: 100%;
        bottom: auto;
    }

    #popup0>.wrapper-popup {
        /*left: 56vw;*/
        right: 100%;
        bottom: auto;
    }

    #popup6>.wrapper-popup {
        right: auto;
    }
}

.overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 300px){
    .popupWrapper {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 3001;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;

        .container {
            z-index: 3001;
            width: 75%;
            position: relative;
            background-color: #FFFFFF;
            border-radius: 15px;
            line-height: 22px;
            padding: 20px 20px;
            flex-direction: column;
            top: 40%;
        }

        .header {
            text-align: center;
        }

        .content {
            text-align: center;
            justify-content: center;
            margin-top: 15px;
            padding-bottom: 30px;
        }

        .btnContainer {
            justify-content: center;
            display: flex;
        }

        .confirmBtn {
            display: flex;
            width: 200px;
            height: 54px;
            padding: 15px 60px;
            justify-content: center;
            gap: 10px;
            border-radius: 10px;
            background-color: #007ABC;
            border: none;
            color: #FFFFFF;
        }
    }

}

@media only screen and (min-width: 992px) {
    .footer__content {
        position: relative;
        bottom: 20px;
        padding: 30px 0 0 0 !important;
    }

    

    /*.popupContainer {*/
    /*    width: 500px;*/
    /*    height: 350px;*/
    /*    background-color: #2ea3b7;*/
    /*    z-index: 3000;*/
    /*    position: fixed;*/ 
    /*}*/
}

@media only screen and (max-width: 730px) {
    .content {
        /* padding-bottom: 160px; */
    }
}

@media only screen and (max-width: 540px) {
    input[type="date"] {
        min-height: 1.2em;
        width: 80% !important;
    }

    #progress-bar-container li .step-inner {
        position: absolute;
        bottom: -40px !Important;
        justify-content: center;
        /* Căn giữ theo chiều ngang */
        align-items: center;
    }

    .faq-button-6 {
        top: 10%;
        right: 30px;
    }

    .faq-button {
        top: 10%;
    }

    .tab-title-3 {
        font-size: 16px;
        ;
        padding: 4px;
    }

    .content {
        /* padding-top: 160px; */
        padding-bottom: 160px;
    }

    .header__title>h4 {
        font-size: 14px;
    }

    .header__logo>img {
        padding: 2.5px;
    }

    .header__progress {
        background: #FFF;
        padding: 12px 10px;
    }

    .wrapper-popup {
        padding: 10px 5px;
        width: 80vw;
        outline: 2px solid #3498db;
        right: 9%;
    }

    .wrapper-popup li {
        font-size: 13px;
    }

    #popup5>.wrapper-popup {
        top: 68vh;
    }

    #popup4>.wrapper-popup {
        top: 53vh;
    }

    #popup3>.wrapper-popup {
        top: 40vh;
    }

    #popup0>.wrapper-popup {
        bottom: 24vh;
        padding: 10px 4px;
    }

    .gap-footer {
        display: none !important;
    }

    .back-progress {
        display: flex;
        width: 100%;
        height: 54px;
        padding: 15px 15px;
    }

    .continue-progress,
    .end-progress {
        display: flex;
        width: 100%;
        height: 54px;
        padding: 15px 15px;
    }

    .logout-process {
        display: flex;
        width: 100%;
        height: 54px;
        padding: 15px 15px;
    }

    .function__title {
        color: #000;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .unit {
        color: #A4CE4E;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        position: relative;
        left: -20px;
    }

    .function__title {
        font-size: 22px;
    }

    .padding-page-2 {
        padding-top: 12rem !important;
    }

    .notes {
        padding: 0 20px;
    }

    .insurance-money__insert {
        padding-top: 20px !important;
        padding-right: 5px !important;
        padding-left: 5px !important;
    }

    .btn__committed-interest-rate {
        padding: 5px 12px;
    }

    .number-money-input {
        padding: 19px 12px;
    }

    .number-percent-input {
        /* /* padding: 19px 12px; */
        padding: 0;
    }

    .number-money-input::placeholder {
        font-size: normal;
        font-weight: 400;
    }

    .form-outline-gap {
        padding-left: 20px;
    }

    

}