#color {
    position: absolute;
    bottom: 75px;
    right: 25px;
    box-shadow: 0 7px 15px -4px rgba(0, 0, 0, 0.4);
    background: #fff;
    border-radius: 50%;
    min-width: 80px;
    min-height: 80px;
    cursor: pointer;
    transform: scale(1.0);
    transition: all ease 0.3s;
    z-index: 999999;
}

#color .drop {
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
    width: 30px;
    height: 30px;
    display: block;
    background: rgb(206, 119, 68);
    background: -moz-linear-gradient(left, rgba(206, 119, 68, 1) 0%, rgba(230, 125, 36, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(206, 119, 68, 1) 0%, rgba(230, 125, 36, 1) 100%);
    background: linear-gradient(to right, rgba(206, 119, 68, 1) 0%, rgba(230, 125, 36, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ce7744', endColorstr='#e67d24', GradientType=1);
    transform: rotate(-45deg);
    margin: 27px auto auto;
    transition: all ease 0.3s;
}

#color:hover {
    transform: scale(1.1);
}

h1 {
    background: #207893;
    background: -moz-linear-gradient(45deg, #207893 25%, #2ea3b7 100%);
    background: -webkit-linear-gradient(45deg, #207893 25%, #2ea3b7 100%);
    background: linear-gradient(45deg, #207893 25%, #2ea3b7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#207893', endColorstr='#2ea3b7', GradientType=1);
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    margin: 0;
    padding: 90px 10% 0 10%;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 4px;
    line-height: 23px;
}

/* --- Start progress bar --- */

.process-wrapper {
    margin: auto;
    max-width: 1080px;
    padding: 5px 0;
}

#progress-bar-container {
    position: relative;
    width: 100%;
    margin: auto;
    height: 35px;
}

#progress-bar-container ul {
    padding: 15px 0 0;
    z-index: 9999;
    position: absolute;
    width: 100%;
    margin: -40px 0 0;
}

#progress-bar-container li.active:before,
#progress-bar-container li:hover:before {
    border: solid 2px #444;
}

#progress-bar-container li {
    list-style: none;
    float: left;
    width: 20%;
    text-align: center;
    color: #aaa;
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
    font-weight: 700;
    transition: all ease 0.2s;
    vertical-align: bottom;
    height: 60px;
    position: relative;
}

#progress-bar-container li .step-inner {
    position: absolute;
    width: 100%;
    bottom: -20px;
    ;
    font-family: NotoSans-Bold, sans-serif;
}

#progress-bar-container li.active,
#progress-bar-container li:hover {
    color: #444;
}

#progress-bar-container li:after {
    content: " ";
    padding-top: -4px;
    display: block;
    width: 23px;
    height: 23px;
    background: #777;
    border: solid 8px #ffffff;
    border-radius: 50%;
    margin: 26px auto auto;
    box-shadow: 0 2px 13px -1px rgba(0, 0, 0, 0.3);
    transition: all ease 0.2s;
}

#progress-bar-container li:hover:after {
    background: #555;
}

#progress-bar-container li.active:after {
    background: #0061a0;
}

#progress-bar-container #line {
    width: 80%;
    margin: auto;
    background: #eee;
    height: 25px;
    position: absolute;
    left: 10%;
    top: px;
    z-index: 1;
    transition: all ease 0.9s;
    box-shadow: inset 1px;
    pointer-events: none;
    border: 1px solid rgb(167, 168, 170);
    border-radius: 24px;
}

#progress-bar-container #line-progress {
    content: " ";
    width: 3%;
    height: 20px;
    background: #207893;
    background: rgba(0, 144, 218, 1) linear-gradient(90deg, rgba(0, 144, 218, 1) 0%, rgba(163, 206, 78, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#207893', endColorstr='#2ea3b7', GradientType=1);
    position: absolute;
    z-index: 2;
    border-radius: 50px;
    transition: all ease 0.9s;
}

#progress-bar-container #line-progress-2 {
    content: " ";
    width: 25%;
    height: 20px;
    background: #207893;
    background: rgba(0, 144, 218, 1) linear-gradient(90deg, rgba(0, 144, 218, 1) 0%, rgba(163, 206, 78, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#207893', endColorstr='#2ea3b7', GradientType=1);
    position: absolute;
    z-index: 2;
    border-radius: 50px;
    transition: all ease 0.9s;
}

#progress-bar-container #line-progress-3 {
    content: " ";
    width: 75%;
    height: 20px;
    background: #207893;
    background: rgba(0, 144, 218, 1) linear-gradient(90deg, rgba(0, 144, 218, 1) 0%, rgba(163, 206, 78, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#207893', endColorstr='#2ea3b7', GradientType=1);
    position: absolute;
    z-index: 2;
    border-radius: 50px;
    transition: all ease 0.9s;
}

#progress-content-section {
    width: 90%;
    margin: auto;
    background: #f3f3f3;
    border-radius: 4px;
}

#progress-content-section .section-content {
    padding: 30px 40px;
    text-align: center;
}

#progress-content-section .section-content h2 {
    font-size: 17px;
    text-transform: uppercase;
    color: #333;
    letter-spacing: 1px;
}

#progress-content-section .section-content p {
    font-size: 15px;
    line-height: 1.8em;
    color: #777;
}

#progress-content-section .section-content {
    display: none;
    animation: FadeInUp 700ms ease 1;
    animation-fill-mode: forwards;
    transform: translateY(15px);
    opacity: 0;
}

#progress-content-section .section-content.active {
    display: block;
}

@keyframes FadeInUp {
    0% {
        transform: translateY(15px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

/* --- ORANGE --- */

body.blue #color .drop {
    background: #207893;
    background: -moz-linear-gradient(45deg, #207893 25%, #2ea3b7 100%);
    background: -webkit-linear-gradient(45deg, #207893 25%, #2ea3b7 100%);
    background: linear-gradient(45deg, #207893 25%, #2ea3b7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#207893', endColorstr='#2ea3b7', GradientType=1);
}

body.blue h1 {
    background: rgb(206, 119, 68);
    background: -moz-linear-gradient(left, rgba(206, 119, 68, 1) 0%, rgba(230, 125, 36, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(206, 119, 68, 1) 0%, rgba(230, 125, 36, 1) 100%);
    background: linear-gradient(to right, rgba(206, 119, 68, 1) 0%, rgba(230, 125, 36, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ce7744', endColorstr='#e67d24', GradientType=1);
}

body.blue li.active:after {
    background: #e67d24;
}

body.blue #line-progress {
    background: rgb(206, 119, 68);
    background: -moz-linear-gradient(left, rgba(206, 119, 68, 1) 0%, rgba(230, 125, 36, 1) 100%);
    background: -webkit-linear-gradient(left, rgba(206, 119, 68, 1) 0%, rgba(230, 125, 36, 1) 100%);
    background: linear-gradient(to right, rgba(206, 119, 68, 1) 0%, rgba(230, 125, 36, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ce7744', endColorstr='#e67d24', GradientType=1);
}

/* --- SOURCE --- */

#inspiration {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 15px;
    font-size: 11px;
    color: #bbb;
    background: #333;
}

#inspiration a {
    color: #777;
}