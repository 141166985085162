@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;500&family=Roboto:wght@100;400&display=swap');

@font-face {
    font-family: NotoSans-Bold;
    src: url(../assets/fonts/NotoSans-Bold.ttf);
}

@font-face {
    font-family: NotoSans-Regular;
    src: url(../assets/fonts/NotoSans-Regular.ttf);
}

#progress-bar-container li .step-inner {
    display: none;
}

.section {
    /* border-bottom: 4px solid #F2F2F2; */
    padding: 5px 0;
}

.header__title,
.section__title,
.footer__content {
    margin: 0;
    padding: 5px 0;
}

.header__progress {
    background: #FFF;
    padding: 10px 0;
}

.header__title {
    background: #0061A0;
}

.header__title>h4 {
    color: #FFF;
    text-align: center;
    font-family: NotoSans-Bold, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: auto;
    padding: 6px;
}

.content {
    /* padding-top: 10px; */
    padding-bottom: 100px;
}

.content-p1 {
    /* padding-top:160px; */
    /* padding-bottom: 30px !important; */
    padding-bottom: 15px !important;
}

.section__title {
    color: #000;
    text-align: center;
    font-family: NotoSans-Bold, sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.header__logo {
    background: #FFF;
}

.header__logo>img {
    padding: 6px 0;
}

.centered-cursor {
    text-align: center;
    /* Align text to the center */
}

.section__choose-gender__box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section__choose-gender__box>img {
    width: 60%;
}

.section__choose-gender--effect {
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    padding: 3px;
}

.selected-effect {
    background: linear-gradient(90deg, #003255 0%, #064789 100%);
}

.section__choose-gender__wrapper {

    background: rgba(0, 144, 218, 1) linear-gradient(90deg, rgba(0, 144, 218, 1) 0%, rgba(163, 206, 78, 1) 100%);
    border-radius: 9px;
    padding: 3px;
}

.section__choose-gender__wrapper_errors {

    background: red;
    border-radius: 9px;
    padding: 3px;
}

.section__choose-gender__wrapper:hover {
    outline: 2px solid #007abc;
    outline-offset: 2px;
}

.section__input-date>input:focus-visible {
    outline: 2px solid #007abc;
    border: none !important;
    color: #0061A0;
}

.section__input-date>input:active {
    color: #0061A0;
}

.section__input-date>.form-control {
    border-radius: 10px;
    border: 1px solid #A7A8AA;
    background: #FFF;
    display: flex;
    width: 53%;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #A7A8AA;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.input-filled {
    color: #0061A0;
}

.section__select-form>.form-select {
    border-radius: 10px;
    border: 1px solid #A7A8AA;
    background: #FFF;
    display: flex;
    width: 374px;
    height: 50px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #A7A8AA;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.section__select-form>.form-select:focus-visible {
    border-radius: 10px;
    border: 2.5px solid #007ABC !important;
    background: #FFF;
    color: #0061A0;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    outline-color: #0061A0;
}

#phoneNumber,
#OTP_input {
    border-radius: 10px;
    border: 1px solid #A7A8AA;
    background: #FFF;
    height: 50px;
}

#sendOTPButton {
    display: flex;
    width: 110px;
    height: 50px;
    padding: 19px 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #007ABC;
    color: #FFF;
}

#sendOTPButton:hover {
    background: #0061A0;
}

.continue-progress,
.end-progress {
    display: flex;
    width: 200px;
    height: 54px;
    padding: 15px 60px;
    justify-content: center;
    /* align-items: center; */
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #4ece6e;
    border: none !important;
    color: #fff;
    margin-left: auto !important;
}

.continue-progress:hover {
    background: #4ece6e;
    color: #fff;
}

.logout-process {
    display: flex;
    width: 200px;
    height: 54px;
    padding: 15px 60px;
    justify-content: center;
    /* align-items: center; */
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #007ABC;
    border: none !important;
    color: #fff;
    margin-left: auto !important;
}

.logout-process:hover {
    background: #007ABC;
    color: #fff;
}

.end-progress:hover {
    background: #0061A0;
    color: #fff;
}

.back-progress {
    display: flex;
    width: 200px;
    height: 54px;
    padding: 15px 60px;
    justify-content: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #007ABC;
    background: #FFF;
    color: #007ABC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


/* .end-progress {
    display: flex;
    width: 200px;
    height: 54px;
    padding: 15px 60px;
    justify-content: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #007ABC;
    background: #FFF;
    color: #007ABC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
} */

/* .end-progress:hover {
    color: #007ABC;
} */

.back-progress:hover {
    color: #007ABC;
}

.countdown {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #007ABC;
}

.continue-progres:focus-visible {
    outline: none !important;
    border: none !important;
}

.section_footer {
    background: #fff;
}

.footer__content {
    color: #000;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

/* PAGE 2  */
.section__value {
    display: block;
}

.padding-page-2 {
    padding-top: 13rem !important;
}

.function__title {
    color: #000;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.unit {
    color: #A4CE4E;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.number-money-input {
    width: 100%;
    height: 55px;
    padding: 19px 31px;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid #A7A8AA;
    background: #FFF;
    text-align: center !important;
}

.number-money-input::placeholder {
    color: #A7A8AA;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: right;
}

.number-percent-input {
    width: 100%;
    height: 55px;
    padding: 19px 31px;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid #A7A8AA;
    background: #FFF;
    text-align: center !important;
}

.text-unit {
    color: #000;
    text-align: right;
    /* font-family: Noto Sans; */
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;

}

.number-percent-input::placeholder {

    color: #A7A8AA;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.calculate-icon>button {
    display: flex;
    width: 100%;
    height: 54px;
    padding: 19px 50px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #007ABC;
    border: none !important;
    color: #fff;
    font-size: 15px;
}

.calculate-icon>button:hover {
    background: #0061A0;
    color: #fff;
}

/* PAGE 3  */
/* table {
  border-collapse: collapse;
  width: 100%;
} */
.section__show_interest_rate {
    display: none;
}

.page3-calculate {
    display: flex;
    width: 100%;
    height: 54px;
    padding: 19px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #007ABC;
    border: none !important;
    color: #fff;
}

.page3-calculate:hover {
    background: #0061A0;
    color: #fff;
}

.text-page3-or {
    color: #A7A8AA;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.page3__btn--left {}

table>tbody>tr>th:first-child {
    width: 5% !important;
}

table>tbody>tr>td:first-child {
    width: 5% !important;
}

table>tbody>tr>th {
    border-bottom: solid 1px #DCDCDC;
    padding: 5px 10px;
    text-align: center;
    width: 9.3%;
    font-size: 14px;
}

table>tbody>tr>td {
    border-bottom: solid 1px #DCDCDC;
    padding: 3px 5px;
    text-align: center;
    width: 9.3%;
}

.header__table__title1 {
    background-color: #0061A0;
}

.header__table__title2 {
    background-color: #A4CE4E;
}

.header__table__title2,
.header__table__title1 {
    padding: 10px 20px;
    text-align: center;
    color: #fff;
}

.table__group1 {
    background-color: #0061A00F;
}

.table__group2 {
    background-color: #FFFFFF;
}

.table__group3 {
    background-color: #A4CE4E1A;
}

.btn__committed-interest-rate {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 10px;
    background: #A4CE4E;
    padding: 16px 10px;
}

.btn__committed-interest-rate:hover {
    color: #FFF;
    background: #7da52d;
}

.tab-title-3 {
    color: #003255 !important;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: auto;
    padding: 6px;
    text-transform: uppercase;
}

.tab-title-3:hover {
    color: #A4CE4E;
}


.page_2_p {
    font-size: 18px;
    font-weight: 600;
}

.tabe_section_3 {
    width: 5%;
}

.tr_class {
    width: 100%;
}

.div_tb {
    overflow-x: auto;
}
